<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                md="4"
            >
                <v-form
                    v-if="!requestStatus"
                    ref="createNewPasswordForm"
                    v-model="valid"
                >
                    <v-card
                        tile
                        elevation="5"
                    >
                        <v-card-title class="py-1">
                            {{ $t('customerportal-newPassword') }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ $t('customerportal-newPassword-info') }}
                        </v-card-subtitle>

                        <v-card-text class="ma-0 py-0">
                            <v-text-field
                                v-model="email"
                                tabindex="1"
                                :rules="globalEmailValidator(email)"
                                :label="$t('myuser/profile-email')"
                                required
                            />

                            <v-text-field
                                v-model="newPassword"
                                tabindex="2"
                                :rules="globalPasswordValidator(newPassword)"
                                :label="$t('forcepasswordchange-tab1title')"
                                :type="showNewPassword ? 'text' : 'password'"
                                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                required
                                @click:append="showNewPassword = !showNewPassword"
                            />

                            <v-text-field
                                v-model="confirmNewPassword"
                                tabindex="2"
                                :rules="globalConfirmPasswordValidator(confirmNewPassword, newPassword)"
                                :label="$t('forcepasswordchange-confirmnewpasswordinputlabel')"
                                :type="showNewPassword ? 'text' : 'password'"
                                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                required
                                @click:append="showNewPassword = !showNewPassword"
                            />

                            <template v-for="code in requestCodes">
                                <v-alert
                                    :key="code"
                                    class="ma-0 mb-1"
                                    dense
                                    :type="requestStatus ? 'success' : 'error'"
                                >
                                    <template v-if="requestStatus">
                                        {{ $t('success-' + code) }}
                                    </template>
                                    <template v-else>
                                        {{ $t('error-' + code) }}
                                    </template>
                                </v-alert>
                            </template>
                        </v-card-text>

                        <v-card-actions>
                            <v-container>
                                <v-row dense>
                                    <v-col>
                                        <v-btn
                                            :to="{ name: 'Login'}"
                                        >
                                            {{ $t('customerportal-goToLogin') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn
                                            :disabled="!valid || !token"
                                            tabindex="2"
                                            type="submit"
                                            @click="ClickCreateNewPassword"
                                        >
                                            {{ $t('leftMenu/smiley-changePassword') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <div
                    v-if="requestStatus"
                    class="text-center"
                >
                    <v-icon
                        size="100"
                    >
                        mdi-check-circle
                    </v-icon>
                    <h1>{{ $t('passwordReset') }}</h1>
                    <v-btn
                        class="my-2"
                        :to="{ name: 'Login' }"
                    >
                        {{ $('customerportal-goToLogin') }}
                    </v-btn>
                </div>
                <v-col
                    cols="12"
                    sm="8"
                    md="6"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    data() {
        return {
            valid: false,

            email: null,
            newPassword: null,
            confirmNewPassword: null,
            token: null,
            requestStatus: false,
            requestCodes: [],

            showNewPassword: false            
        }
    },
    computed: {
    },
    created() {   
        this.token = this.$route.query.token    
        if(!this.token){
            this.requestCodes.push('error-tokenmissing')
        }
    },    
    methods: {
        ClickCreateNewPassword(e){

            //Important when using 'history' mode in router
            e.preventDefault()

            this.requestCodes = []
            this.requestStatus = false
            this.ConfirmPasswordReset({
                email: this.email,
                newPassword: this.newPassword,
                token: this.token
            })
                .then(data => {
                    if(data.success){
                        this.email = null,
                        this.newPassword = null,
                        this.confirmPassword = null
                    }     
                    
                    this.requestStatus = data.success
                    this.requestCodes = data.codes
                })
        },
        ...mapActions({
            ConfirmPasswordReset: 'ConfirmPasswordReset'
        })
    }
}
</script>
